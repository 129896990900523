import { useContext, useEffect, useState } from "react";
import { calculationsListAPI } from "../../../../../../services/api/calculator";
import { Link } from "react-router-dom";
import { format_datetime_convert } from "../../../../../../helpers/tools";
import DarkCard from "../../../../../../components/card/DarkCard";
import styled from "styled-components";
import { Colors } from "../../../../../../components/theme";
import { useTranslation } from "react-i18next";
// import { ProjectContext } from "../../../../../../contexts/AppContext";

const ListElement = styled.div`
	border-radius: 5px;
	padding: 8px 15px;
	margin: 15px 0px;
	background-color: ${Colors.lightBG};
`;

const SavedCalculations = () => {
	const { t } = useTranslation();
	const [calcList, setCalcList] = useState([]);

	useEffect(() => {
		const getCalculationsList = async () => {
			try {
				const response = await calculationsListAPI();
				const new_data = response.data.map(el => ({
					...el,
					created: format_datetime_convert(el.created),
					modify: format_datetime_convert(el.modify),
				}));
				setCalcList(new_data);
			} catch (e) {
				console.error(e);
			}
		};

		getCalculationsList();
	}, []);

	return (
		<>
			<DarkCard classNames='mb-3'>Breadcrumbs: Calculations list</DarkCard>
			<DarkCard classNames='mb-3'>
				<h1 className='mt-0'>{t("calculator.saved_list_title")}</h1>

				{calcList.length < 1
					? <p>{t("calculator.no_data")}</p>
					:	calcList.map(el => (
							<ListElement key={el.pk}>
								<Link to={`/sales/calculator/${el.pk}`}>
									<b style={{ fontSize: "18px" }}>{el.name}</b>&nbsp;-&nbsp;
									{t("calculator.created_date_time")} {el.created}&nbsp;-&nbsp;
									{t("calculator.modified_date_time")} {el.modify}
								</Link>
							</ListElement>
						))
				}
			</DarkCard>
		</>
	);
};

export default SavedCalculations;
