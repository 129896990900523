import {useTranslation} from "react-i18next";
import {DetailsStyle, ElementStyle, FieldStyle, NoteParagraphStyle, NoteStyle} from "../../../../../../layouts/styles";
import {Colors} from "../../../../../../components/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faFilePdf} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import dateFormat from "dateformat";
import Popup from "../../../../../../components/ui/Popup/Popup";
import {Button} from "../../../../../../components/ui/Button";
import ParametersPdfForm from "./ParametersPdfForm";
import {languagesList} from "../../../../../../helpers/dictionary/users";
import {toInteger} from "lodash";
import get_calculation_api from "../../../../../../helpers/PDFSupport/offers/get_calculation_api";
import {popupError} from "../../../../../../components/ui/Popup/feedBack";
import {RenderDataToPDF} from "../../../../../../helpers/PDFSupport/RenderDataToPDF";
import {RedirectButton} from "../../../../../../helpers/Redirect";

const popupInit = {show: false, title: 'Generate PDF - parametry dokumentu', variant: 'error', message: ''}
const INITIAL_OPTIONS = { value: 0, label: "-----" };
const popupInitial = {show: false,}
const INITIAL_renderDataToPDF = {
    project: {}, // {name: "", id: ""}
    estimateFormDataVisible: false,
    clientsList: INITIAL_OPTIONS,
    client: INITIAL_OPTIONS,
    firm: INITIAL_OPTIONS,
    calculation: {},
    program: "",
    comment: "",
    language: INITIAL_OPTIONS,
    estimateDate: new Date(),
    showEstroGrant: false,
    popup: popupInitial,
    isGeneratePDF: false,
    estimateID: undefined,
}
const processName = 'estimate'

const EstimateItem = ({est, grantEstroSum, index, project, clientAndFirm, redirect_to}) => {
    const [createDate, setCreateDate] = useState(new Date());
    const [language, setLanguage] = useState({ value: "1", label: "Lëtzebuergesch" });
    const [showEstroGrant, setShowEstroGrant] = useState(false);
    const [isGeneratePDF, setIsGeneratePDF] = useState(false)
    const [projectProgram, setProjectProgram] = useState("")
    const [pdfData, setPdfData] = useState(INITIAL_renderDataToPDF);
    const [popup, setPopup] = useState(popupInit);
    const {t} = useTranslation()

    useEffect(() => {
        setCreateDate(new Date(est.date))
        setShowEstroGrant(est.showEstroGrant)
        const lang = languagesList.find(
            item => toInteger(item.value) === est.language
        );
        setLanguage(lang)

        // const estimation_date = sessionData.estimateDate ? new Date(sessionData.estimateDate) : ""

    }, [])

    const handlePopup = (e) => {
        const buttonName = e.target.name

        if (buttonName === 'save') {
            const getCalculationAPI = async () => {
                const response = await get_calculation_api(est.calculation__pk)
                const dataEst = {
                    ...pdfData,
                    calculation: response,
                    project: project,
                    client: clientAndFirm.client,
                    firm: clientAndFirm.firm,
                    estimateID: est.id,
                    estimateDate: createDate.toLocaleDateString("de-DE"),
                    projectProgram: projectProgram,
                }

                console.info("get_calculation_api: ", dataEst)

                setPdfData(dataEst)
                setPopup({show: false})
                setIsGeneratePDF(true)
            }
            getCalculationAPI()
        } else {
            setPopup(popupInit);
        }
    }

    const handlePDFClick = (id) => {
        setPopup({...popupInit, show: true, variant: "editF"});
    }

    const handleChangeData = (data) => {
        console.log("handleChangeData: ", data);
        if (data.type === "SET_DATE") {
            setCreateDate(data.value)
        }
        if (data.type === "SET_SHOW_ESTRO_GRANT") {
            setShowEstroGrant(data.value)
        }
        if (data.type === "SET_LANGUAGE") {
            setLanguage(data.value)
        }
        if (data.type === "SET_PROJECT_PROGRAM") {
            setProjectProgram(data.value)
        }
    }

    if (popup.show) {
        return (<Popup message={popup.message} title={popup.title} variant={popup.variant} >
            <ParametersPdfForm
                formData={{
                    date: createDate,
                    language: language,
                    showEstroGrant: showEstroGrant,
                    grantEstroSum: grantEstroSum,
                    projectProgram: projectProgram
                }}
                dispatch={handleChangeData}
            />
            <Button name="cancel" onClick={(e) => handlePopup(e)} variant="round" bWidth="small" styling="bordered" className='mx-3'>{t("Cancel")}</Button>
            <Button name="save" onClick={(e) => handlePopup(e)} variant="round" bWidth="small" styling="plain" className='mx-3'>{t("Generate")}</Button>

        </Popup>)
    }

    return (<>
        <ElementStyle className={"d-flex flex-column"} style={{backgroundColor:'white', marginTop:'10px', marginBottom:'10px'}}>
            <DetailsStyle className="d-flex flex-row flex-wrap flex-md-nowrap">
                <div className="d-flex flex-row flex-wrap flex-md-nowrap" style={{width: '100%'}}>
                    <FieldStyle width='20px'>
                        {index}
                    </FieldStyle>
                    <FieldStyle>
                        <a onClick={() => redirect_to("project", est.calculation__pk, est.id, est.current)}>
                            <b>{t("project.id_title")}: E{est.id}</b> / {est.calculation__name}
                        </a>
                    </FieldStyle>
                    <FieldStyle>
                        {est.client__first_name} {est.client__last_name}
                    </FieldStyle>
                    <FieldStyle>
                        {t("project.creation_date")}: {dateFormat(est.date, "dd.mm.yyyy")}
                    </FieldStyle>
                    <FieldStyle>
                        {est.current
                            ?   <span style={{color:`${Colors.green}`, fontWeight:'bold'}}>
                                    <FontAwesomeIcon icon={faCheckCircle} className="me-2"/>
                                {t("project.selected_title")}
                                </span>
                            : '-' }
                    </FieldStyle>
                    <FieldStyle>
                        <a onClick={() => {handlePDFClick(est.id)}}>
                            <FontAwesomeIcon icon={faFilePdf} className="me-2"/>
                            {t("common.button.download_pdf")}
                        </a>
                    </FieldStyle>
                </div>
            </DetailsStyle>
            {
                'lcomment'
                    ?   <NoteStyle>
                        <NoteParagraphStyle>
                            <b>{t("project.comment_title")}: </b>
                            {est.calc_notes}
                        </NoteParagraphStyle>
                    </NoteStyle>
                    : null
            }
        </ElementStyle>

        {isGeneratePDF
            ? <RenderDataToPDF
                dataToRender={pdfData}
                processName={processName}
                dispatch={setIsGeneratePDF}
                popupError={popupError}
                popupInitial={popupInitial}
              />
            : null
        }
    </>)
}

export default EstimateItem;
