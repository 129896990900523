import DarkCard from "../../../../../../components/card/DarkCard";
import {useNavigate, useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import LightCard from "../../../../../../components/card/LightCard";
import Budget from "./Budget";
import Error404 from "../../../../../Error404";
import LoadingData from "../../../../../LoadingData";
import Estimate from "./Estimate";
import {project} from "../../../../../../services/api/endpoints";
import {useEndpoint} from "../../../../../../hooks/useEndpiont";
import Status from "./Status";
import Quote from "./Quote";
import Staff from "./Staff"
import Events from "./Events";
import Feed from "./Feed";
import {useTranslation} from "react-i18next";
import {RedirectButton} from "../../../../../../helpers/Redirect";
import ClientOrFirmCard from "./ClientOrFirmCard";
import RedHeader from "../../../../../../components/ui/RedBgTitleHeader";
import {useSessionStorage} from "usehooks-ts";
import {projectSession} from "../../../../../../services/sessionData";


export default function ProjectCard() {
    const params = useParams()
    const [{data, loading, error}] = useEndpoint(project.details(params.id))
    const projectData = data
    const {t} = useTranslation()
    const navigate = useNavigate();
    const [sessionData, setSessionData] = useSessionStorage("CALCULATION", projectSession);
    const [estimate_current, setEstimateCurrent] = useState(0)

    useEffect(() => {
        if (projectData) {
            setEstimateCurrent(projectData.estimates.find(el => el.current).id)
        }
    }, [projectData])

    const redirect_to = (what, calculation_id, estimation_id, currentEst) => {
        const estimate_selected = estimation_id ? estimation_id : estimate_current;
        if (what === 'project') {
            // console.log("project => appData:")
            // const estimate_id = estimate_selected ? estimate_selected.id : 0
            setSessionData({
                ...sessionData,
                name: "project",
                pathReturn: "/projects/in-preparation/"+params.id,
                projectID: params.id,
                projectName: projectData.name,
                calculationID: projectData.calculation_pk,
                estimateID: estimate_selected,
                estimateCurrent: currentEst,
            })
            navigate("/sales/calculator/"+calculation_id)
        } else {    // Create Estimate
            setSessionData({
                ...sessionData,
                name: "estimate",
                pathReturn: "/projects/in-preparation/"+params.id,
                projectID: params.id,
                projectName: projectData.name,
                calculationID: 0,
                estimateID: estimate_selected,
                estimateCurrent: false,
            })
            navigate("/sales/calculator")
        }
    }

    if (loading) return <LoadingData/>
    if (error) return <Error404/>
    if (projectData.status === 404) return (
        <DarkCard classNames='mb-3 row'>
            Breadcrumbs: Projects > Project ID: P{params.id}<br /><br />
            No data
        </DarkCard>
    )

    const clientAndFirm = {
        client: projectData.clientData,
        firm: projectData.firmData,
    }

    console.info("projectData:", projectData)

    return (<>
        <DarkCard classNames='mb-3'>
            Breadcrumbs: Projects > Project ID: P{params.id}
        </DarkCard>
        <DarkCard classNames='mb-3'>
            <h1 className='mt-0 mb-0'>{projectData.name}</h1>
            <p className="mt-0">Estimate ID: E{estimate_current}</p>

            <LightCard classNames={'mb-3'}>
                <Status />
            </LightCard>

            {projectData.estimates.length > 0
                ? (<>
                    <div className='mb-3'>
                        <RedirectButton redirect_to_path={() => redirect_to("project", projectData.calculation_pk, true)}>
                            {t("common.button.edit_estimate")}
                        </RedirectButton>
                        
                        <div className="row">
                            <div className="col-6">
                                <LightCard>
                                    <RedHeader title={t('project.client_title')}/>
                                    <ClientOrFirmCard details={clientAndFirm} />
                                </LightCard>
                            </div>
                            <div className="col-6">
                                <LightCard>
                                    <RedHeader title={t('project.budget_title')}/>
                                    <Budget projectData={projectData} />
                                </LightCard>
                            </div>
                        </div>
                        
                    </div>

                    <div className='mb-3'>
                        <div className="mt-5 mb-2" style={{overflow:'hidden'}}>
                            <RedirectButton redirect_to_path={() => redirect_to("estimate")}>
                                {t("common.button.create_estimate")}
                            </RedirectButton>
                        </div>

                        <LightCard>
                            <Estimate
                                estimateData={projectData.estimates}
                                clientAndFirm={clientAndFirm}
                                grantEstroSum={projectData.totaling.grantEstroSum}
                                project={{name: projectData.name, id: projectData.id}}
                                redirect_to={redirect_to}
                            />
                        </LightCard>
                    </div>

                    <div className='mb-3'>
                        <LightCard>
                            <Quote />
                        </LightCard>
                    </div>

                    <div className='mb-3'>
                        <div className="mt-5 mb-2" style={{overflow:'hidden'}}>
                            <RedirectButton redirect_to_path={() => redirect_to("project", projectData.calculation_pk, true)}>
                                {t("common.button.edit_estimate")}
                            </RedirectButton>

                        </div>

                        <LightCard>
                            <Staff staff={projectData.staff} kzmProject={projectData.kzmProject} estimationID={estimate_current}/>
                        </LightCard>
                    </div>

                    <div className='mb-3'>
                        <div className="mt-5 mb-2" style={{overflow:'hidden'}}>
                            <RedirectButton redirect_to_path={() => redirect_to("project", projectData.calculation_pk, true)}>
                                {t("common.button.edit_estimate")}
                            </RedirectButton>
                        </div>
                        <LightCard>
                            <Events eventDetail={projectData.eventDetail} estimationID={estimate_current} />
                        </LightCard>
                    </div>
                  </>)
                : <div>{t("no data")}</div>
            }
        </DarkCard>
        <DarkCard>
            <div className='mb-3'>
                <LightCard>
                    <Feed feedData={projectData.feeds} />
                </LightCard>
            </div>
        </DarkCard>
    </>)


}