import RedHeader from "../../../../../../components/ui/RedBgTitleHeader";
import React from "react";
import {useTranslation} from "react-i18next";
import WhiteCard from "../../../../../../components/card/WhiteCard";
import FeedDetails from "./FeedDetails";

export default function Feed({feedData}) {
    const {t} = useTranslation()

    return (<>
        <RedHeader title={t('project.feed_title')}/>
        {feedData.map((box, index) =>
            <WhiteCard  id={"white_card_"+index} classNames='mt-3 d-flex flex-row flex-wrap flex-md-nowrap align-items-center p-3'>
                <div className='row'>
                    <dev>{Object.keys(box)[0]}<br /></dev>
                    <FeedDetails data={Object.values(box)[0]}/>
                    <br />
                </div>
            </WhiteCard>
        )}

    </>)
}