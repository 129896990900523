import {DetailsStyle, ElementStyle, FieldStyle, NoteParagraphStyle, NoteStyle} from "../../../../../../layouts/styles";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import styled from "styled-components";

const titleField = {
    id: "project.id_title",
    name: "project.project_name_title",
    start_date: "project.project_start_date_title",
    project_cost: "project.project_project_cost_title",
    profit_loss: "project.project_project_profit_loss_title",
}

const ProfitLoss = styled.span `
    color: #fff;
    font-weight: bold;
    background-color: ${props => props.profit_loss > 0 ? '#b41f23' : '#69b41f'};
    border-radius: 50px 50px;
    padding: 2px 12px;
`

export function ProjectItem({project}) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const handleClick = (pk) => {navigate("/projects/in-preparation/"+pk)}
    const profit_loss = project.totaling.estroSum - project.totaling.clientSum

    return(<>
        <ElementStyle className={"d-flex flex-column"} key={project.id}>
            <DetailsStyle className="d-flex flex-row flex-wrap flex-md-nowrap" />
            <div className="d-flex flex-row flex-wrap flex-md-nowrap py-1" style={{width: '95%'}}>
                <FieldStyle width="13%" className="p-1">
                    {t(titleField.id)}<br/>
                    <a onClick={() =>{handleClick(project.id)}}><b>P{project.id}</b></a>
                </FieldStyle>

                <FieldStyle className="p-1">
                    {t(titleField.name)}<br/>
                    <a onClick={() =>{handleClick(project.id)}}><b>{project.name}</b></a>
                </FieldStyle>

                <FieldStyle className="p-1">
                    {t(titleField.start_date)}<br/>
                    {project.start_date ? project.start_date : "-----"}
                </FieldStyle>

                <FieldStyle className="p-1">
                    {t(titleField.project_cost)}<br/>
                    {project.totaling.totalSum} €
                </FieldStyle>

                <FieldStyle className="p-1">
                    {t(titleField.profit_loss)}<br/>

                    <ProfitLoss profit_loss={profit_loss} >
                        {profit_loss.toFixed(2)} €
                    </ProfitLoss>
                    <br/>
                </FieldStyle>
            </div>
        </ElementStyle>
    </>)
}