import RedHeader from "../../../../../../components/ui/RedBgTitleHeader";
import React from "react";
import {useTranslation} from "react-i18next";
import DarkCard from "../../../../../../components/card/DarkCard";
import LightCard from "../../../../../../components/card/LightCard";
import {instrNameCol1, instrNameCol2, instrNameCol3, instrNameCol4} from "../../../../../../helpers/dictionary/users";
import {stylesStaffTable} from "../../../../../../helpers/PDFSupport/offers/Styles/Styles";

const contextStaff = (el, i, value) => (
    <div key={i} className="d-flex flex-row align-items-center mb-1">
        <div style={{width:'35%'}}>
            {el} 
        </div>
        <div style={{width:'20%'}}>
            {value || "-"}
        </div>
    </div>
)


export default function Staff({staff, kzmProject, estimationID}) {
    const {t} = useTranslation()

    return (<>
        <RedHeader title={t('project.staff_title')+" (E"+estimationID+")"}/>

            <div className='mb-3 row'>
                <LightCard>
                    <div className='row px-2'>
                        <div className='col-sm-6 col-md-3 d-flex flex-column'>
                            {instrNameCol1.map((el, i) => contextStaff(el, i, staff[el]))}
                        </div>
                        <div className='col-sm-6 col-md-3 d-flex flex-column'>
                            {instrNameCol2.map((el, i) => contextStaff(el, i, staff[el]))}
                        </div>
                        <div className='col-sm-6 col-md-3 d-flex flex-column'>
                            {instrNameCol3.map((el, i) => contextStaff(el, i, staff[el]))}
                        </div>
                        <div className='col-sm-6 col-md-3 d-flex flex-column'>
                            {instrNameCol4.map((el, i) => contextStaff(el, i, staff[el]))}
                        </div>
                    </div>
                    <div>
                        {t("project.kzm_status")} {kzmProject ? t("Yes") : t("No")}
                    </div>
                </LightCard>
            </div>

    </>)
}