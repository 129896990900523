import {useTranslation} from "react-i18next";
import WhiteCard from "../../../../../../components/card/WhiteCard";
import {split} from "lodash";


export default function FeedDetails({data}) {
    const {t} = useTranslation()

    return (<>
        {data.map((box, index1) =>
                <div key={"boxDetails_"+index1}>
                    <dev>{box.user} --- {box.created_time}<br /></dev>
                    <dev>{box.note}</dev><br />
                    {box.attachments.map((attachment, index2) => (
                        <a key={"boxAttach_" + index2} href={`${process.env.REACT_APP_API_HOST}/${attachment.file}`}
                           target="_blank">
                            {split(attachment.name, "\/")[1]}
                            <br/>
                        </a>
                    ))}
                </div>
        )}

    </>)
}